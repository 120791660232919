import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@apollo/client';

import { useExpenses } from '@legalsurf/hooks';
import { useSnackbar } from 'src/components/v3/Snackbar';

import Table from 'src/components/v3/Table';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import { DeleteExpenseMutation } from 'src/pages/Dashboard/Expenses/ExpensesQueries';
import { ExpensesQuery } from '@legalsurf/queries';

import { useDialog } from 'src/dialogs/Dialogs';
import Header, { useExpensesTableSearchFilterValues } from './Header';
import { getColumns } from './columns';
import { useExpensesToExport } from './excel';
import { Stack } from '@mui/material';

const columns = getColumns();

const initialEntitiesQueryVars = {
  entities: [],
  filecases: [],
  responsibles: [],
  currencies: [],
  tags: [],
};

const useExpensesTable = (options = {}) => {
  const studioId = useStudioId();
  const [params, , initialized] = useExpensesTableSearchFilterValues();

  const queryVariables = {
    studio: studioId,
    entities: params.entities ?? initialEntitiesQueryVars.entities,
    filecases: params.filecases ?? initialEntitiesQueryVars.filecases,
    responsibles: params.responsibles ?? initialEntitiesQueryVars.responsibles,
    currencies: params.currencies ?? initialEntitiesQueryVars.currencies,
    tags: params.tags ?? initialEntitiesQueryVars.tags,
  };

  const { data, ...rest } = useExpenses(queryVariables, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    skip: !studioId || !initialized,
    ...options,
  });

  return { expenses: data, ...rest };
};

const ExpensesTable = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const { openSnackbar } = useSnackbar();
  const dispatchExpenseDialog = useDialog('expense');
  const { expenses, loading, variables, refetch } = useExpensesTable({
    onCompleted: () => setSelectedItems([]),
  });

  const [deleteExpense, { loading: isDeletingExpense }] = useMutation(
    DeleteExpenseMutation,
    {
      refetchQueries: [ExpensesQuery, GET_ENTITY_SINGLE_PAGE_METADATA],
    },
  );

  const handleDelete = async () => {
    try {
      const plural = selectedItems.length ? 'Gastos' : 'Gasto';

      const promises = selectedItems.map((id) =>
        deleteExpense({
          variables: {
            id,
          },
        }),
      );

      await Promise.allSettled(promises);

      openSnackbar(`${plural} eliminado exitosamente.`, {
        severity: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar(
        'Hubo un problema al eliminar el/los gastos. Intente mas tarde.',
        {
          severity: 'error',
        },
      );
    }
  };

  const { getExpensesToExport, loading: loadingExpensesToExport } =
    useExpensesToExport();

  const handleDownloadAsExcel = async () => {
    await getExpensesToExport({ ...variables, ids: selectedItems });
  };

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        refetch={refetch}
        selectedItems={selectedItems}
        subtitle={`Totales ${expenses?.length || '0'}`}
        title="Gastos"
        totalItems={expenses?.length ?? 0}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'expenses',
          },
          noRowsOverlay: {
            variant: 'expenses',
          },
        }}
        loading={loading}
        paginationMode="client"
        rows={expenses ?? []}
        rowsPerPageOptions={[]}
        rowSelectionModel={selectedItems}
        onRowClick={(params) =>
          dispatchExpenseDialog(params.row.id, { expenseId: params.row.id })
        }
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
      />
    </Stack>
  );
};

export default ExpensesTable;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GET_PAGINATED_ENTITIES } from 'src/graphql/queries/entities';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header, { useEntitiesTableSearchFilterValues } from './Header';
import { useColumns } from './columns';
import { useEntitiesToExport } from './excel';
import { Stack } from '@mui/material';

const initialEntitiesQueryVars = {
  limit: defaultPageSize,
  offset: 0,

  tags: [],
  search: '',
  type: null,
  state: null,
};

const usePaginatedEntitiesTable = (options = {}) => {
  const studioId = useStudioId();
  const [params, , initialized] = useEntitiesTableSearchFilterValues();

  const queryVariables = {
    studio: studioId,
    limit: defaultPageSize,
    offset: 0,

    search: params.search ?? initialEntitiesQueryVars.search,
    tags: params.tags ?? initialEntitiesQueryVars.tags,
    type: (params.type ?? initialEntitiesQueryVars.type) || undefined,
    state: params.state ?? initialEntitiesQueryVars.state,
  };

  const { data: { paginatedEntities } = { paginatedEntities: {} }, ...rest } =
    useQuery(GET_PAGINATED_ENTITIES, {
      notifyOnNetworkStatusChange: true,
      partialRefetch: true,
      fetchPolicy: 'cache-and-network',
      variables: queryVariables,
      skip: !studioId || !initialized,
      ...options,
    });

  return { paginatedEntities, ...rest };
};

const DirectoriesTable = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
  });

  const { paginatedEntities, loading, variables, refetch } =
    usePaginatedEntitiesTable({
      onCompleted: () => setSelectedItems([]),
    });
  const columns = useColumns();

  const handlePageChange = ({ pageSize, page }) =>
    refetch({
      ...variables,
      limit: pageSize,
      offset: pageSize * page,
    }).then(() => {
      setPagination({ page, limit: pageSize });
    });

  const { getEntitiesToExport, loading: loadingEntitiesToExport } =
    useEntitiesToExport();

  const handleDownloadAsExcel = async () => {
    const params = { ...variables, ids: selectedItems };

    if (!selectedItems?.length) {
      params.limit = paginatedEntities?.data?.count;
    }

    await getEntitiesToExport(params);
  };

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        loadingEntitiesToExport={loadingEntitiesToExport}
        refetch={refetch}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        subtitle={`Totales ${paginatedEntities?.count || '0'}`}
        title="Directorio"
        totalItems={paginatedEntities?.count ?? 0}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'entities',
          },
          noRowsOverlay: {
            variant: 'entities',
          },
        }}
        loading={loading || loadingEntitiesToExport}
        paginationModel={{ page: pagination.page, pageSize: pagination.limit }}
        rowCount={paginatedEntities?.count ?? 0}
        rowSelectionModel={selectedItems}
        rows={paginatedEntities?.data ?? []}
        onPaginationModelChange={handlePageChange}
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        onSortModelChange={([column]) => {
          refetch({
            ...variables,
            ...(column
              ? {
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  orderBy: null,
                  sortOrder: null,
                }),
          });
        }}
      />
    </Stack>
  );
};

export default DirectoriesTable;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';

import Table from 'src/components/v3/Table';
import { SalesQuery } from 'src/pages/Dashboard/Sales/SalesQueries';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import Header, { useSalesTableSearchFilterValues } from './Header';
import { useSalesToExport } from './excel';
import { useSalesColumns } from './columns';

const initialEntitiesQueryVars = {
  entities: [],
  filecases: [],
  responsibles: [],
  currencies: [],
  tags: [],
};

const useSalesTable = (options = {}) => {
  const studioId = useStudioId();
  const [params, , initialized] = useSalesTableSearchFilterValues();

  const queryVariables = {
    studio: studioId,
    entities: params.entities ?? initialEntitiesQueryVars.entities,
    filecases: params.filecases ?? initialEntitiesQueryVars.filecases,
    responsibles: params.responsibles ?? initialEntitiesQueryVars.responsibles,
    currencies: params.currencies ?? initialEntitiesQueryVars.currencies,
    tags: params.tags ?? initialEntitiesQueryVars.tags,
  };

  const { data: { sales } = { sales: {} }, ...rest } = useQuery(SalesQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !studioId || !initialized,
    ...options,
  });

  return { sales, ...rest };
};

const SalesTable = () => {
  const columns = useSalesColumns();
  const [selectedItems, setSelectedItems] = useState([]);

  const dispatchSaleDialog = useDialog('sale');

  const { sales, loading, variables, refetch } = useSalesTable({
    onCompleted: () => setSelectedItems([]),
  });

  const { getSalesToExport } = useSalesToExport();

  const handleDownloadAsExcel = async () => {
    await getSalesToExport({ ...variables, ids: selectedItems });
  };

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        refetch={refetch}
        selectedItems={selectedItems}
        subtitle={`Totales ${sales?.length || '0'}`}
        title="Honorarios"
        totalItems={sales?.length ?? 0}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'sales',
          },
          noRowsOverlay: {
            variant: 'sales',
          },
        }}
        loading={loading}
        paginationMode="client"
        rows={sales ?? []}
        rowsPerPageOptions={[]}
        rowSelectionModel={selectedItems}
        onRowClick={(params) =>
          dispatchSaleDialog(params.row.id, { id: params.row.id })
        }
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
      />
    </Stack>
  );
};

export default SalesTable;

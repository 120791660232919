import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Download } from '@mui/icons-material';
import * as Sentry from '@sentry/browser';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeader, {
  SectionHeaderActiveFilters,
  SectionHeaderHeading,
} from 'src/components/v3/SectionHeader';
import {
  CurrencyAutocomplete,
  EntitiesAutocomplete,
  FilecasesAutocomplete,
  StudioMembersAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';
import { ArrayParam } from 'use-query-params';
import { useSearchFilterValues } from 'src/components/v3/SectionHeader/utils';
import { LSAdd } from 'src/components/icons';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import MoreButton from 'src/components/v2/MoreButton';
// import MoreButton from 'src/components/v2/MoreButton';

export const SalesTableSearchFiltersId = 'SalesTableSearchFilters-v1';

const paramConfigMap = {
  filecases: ArrayParam,
  entities: ArrayParam,
  responsibles: ArrayParam,
  currencies: ArrayParam,
  tags: ArrayParam,
};

export const salesTableHeaderDefaultInitialValues = {
  filecases: [],
  entities: [],
  responsibles: [],
  currencies: [],
  tags: [],
};

export const useSalesTableSearchFilterValues = () =>
  useSearchFilterValues({ paramConfigMap, id: SalesTableSearchFiltersId });

const Header = ({
  title,
  subtitle,
  refetch,
  loading,
  onReset,
  totalItems,
  selectedItems,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const [params, setParams] = useSalesTableSearchFilterValues();

  const dispatchSalesFormDialog = useDialog('salesForm');

  const initialValues = {
    filecases:
      params.filecases ?? salesTableHeaderDefaultInitialValues.filecases,
    entities: params.entities ?? salesTableHeaderDefaultInitialValues.entities,
    responsibles:
      params.responsibles ?? salesTableHeaderDefaultInitialValues.responsibles,
    currencies:
      params.currencies ?? salesTableHeaderDefaultInitialValues.currencies,
    tags: params.tags ?? salesTableHeaderDefaultInitialValues.tags,
  };

  const handleFiltersSubmit = async (values) => {
    const query = {
      filecases:
        values.filecases?.map((filecase) => filecase.value ?? filecase) ??
        values.filecases,
      entities:
        values.entities?.map((entity) => entity.value ?? entity) ??
        values.entities,
      responsibles:
        values.responsibles?.map(
          (responsible) => responsible.value ?? responsible,
        ) ?? values.responsibles,
      currencies:
        values.currencies?.map((currency) => currency.value ?? currency) ??
        values.currencies,
      tags: values.tags?.map((tag) => tag.value ?? tag) ?? values.tags,
    };

    try {
      setParams(values);

      await refetch(query);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          innerParams: JSON.stringify(query),
        },
      });

      // try reseting parameters
      setParams({});
    }
  };

  const [t, setT] = useState(null);

  const handleFiltersChange = (values, prev) => {
    if (values.search !== prev.search) {
      if (t) clearTimeout(t);

      setT(setTimeout(() => handleFiltersSubmit(values), 400));
      return;
    }

    handleFiltersSubmit(values);
  };

  const handleReset = () => {
    onReset?.();
    setParams({});
  };

  return (
    <SectionHeader
      enableReinitialize
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersSubmit}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack direction="column">
              <SectionHeaderHeading sx={{ mb: 0.5 }} variant="h3">
                {title}
              </SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <FilecasesAutocomplete
                multiple
                name="filecases"
                size="small"
                label="Expedientes"
              />

              <StudioMembersAutocomplete
                multiple
                name="responsibles"
                size="small"
                label="Responsables"
              />

              <EntitiesAutocomplete
                multiple
                name="entities"
                size="small"
                label="Directorios"
              />

              <TagsAutocomplete
                multiple
                label="Etiquetas"
                name="tags"
                size="small"
              />

              <CurrencyAutocomplete
                multiple
                label="Monedas"
                name="currencies"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{
                ml: 'auto',
                gap: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.DIRECTORY.CREATE]}
              >
                <Button
                  color="primary"
                  startIcon={<LSAdd color="white" />}
                  variant="contained"
                  onClick={() => dispatchSalesFormDialog('SalesTableForm')}
                >
                  Crear Honorario
                </Button>
              </PermissionGuard>

              <MoreButton popperHeader="Acciones">
                <Stack gap={0.5} p={1}>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.DIRECTORY.ACCESS]}
                  >
                    <Button
                      // disabled={isDeleting || loadingEntitiesToExport}
                      size="large"
                      startIcon={<Download />}
                      variant="text"
                      onClick={handleDownloadAsExcel}
                    >
                      Descargar Excel ({selectedItems?.length || totalItems})
                    </Button>
                  </PermissionGuard>
                </Stack>
              </MoreButton>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecases: 'Expedientes',
              entities: 'Directorios',
              responsibles: 'Responsables',
              tags: 'Etiquetas',
              currencies: 'Monedas',
            }}
            filters={params}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;

import React, { useState } from 'react';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam } from 'use-query-params';
import * as Sentry from '@sentry/browser';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import { SelectField, TextField } from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen, LSTrash } from 'src/components/icons';
import { EntityStatusAutocomplete, TagsAutocomplete } from 'src/autocompletes';
import { useSearchFilterValues } from 'src/components/v3/SectionHeader/utils';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { entityTypes, LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useMutation } from '@apollo/client';
import MoreButton from 'src/components/v2/MoreButton';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Download } from '@mui/icons-material';
import { DELETE_ENTITIES } from 'src/graphql/mutations/entities';
import { GET_PAGINATED_ENTITIES } from 'src/graphql/queries/entities';

export const EntitiesTableSearchFiltersId = 'EntitiesTableSearchFilters-v1';

const paramConfigMap = {
  type: StringParam,
  state: StringParam,
  search: StringParam,
  tags: ArrayParam,
};

export const entitiesTableHeaderDefaultInitialValues = {
  type: null,
  state: null,
  search: null,
  tags: [],
};

export const useEntitiesTableSearchFilterValues = () =>
  useSearchFilterValues({ paramConfigMap, id: EntitiesTableSearchFiltersId });

const Header = ({
  title,
  subtitle,
  refetch,
  loading,
  onReset,
  totalItems,
  selectedItems,
  setSelectedItems,
  loadingEntitiesToExport,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const studioId = useStudioId();
  const dispatchEntityFormDialog = useDialog('entityForm');
  const [params, setParams] = useEntitiesTableSearchFilterValues();

  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteEntities, { loading: isDeleting }] = useMutation(
    DELETE_ENTITIES,
    {
      refetchQueries: [GET_PAGINATED_ENTITIES],
      update: (cache, data, options) => {
        setSelectedItems([]);

        const normalizedId = cache.identify({
          id: options.variables.entity,
          __typename: 'Entity',
        });

        cache.evict({ id: normalizedId });
        cache.gc();
      },
    },
  );

  const initialValues = {
    type: params.type ?? entitiesTableHeaderDefaultInitialValues.type,
    state: params.state ?? entitiesTableHeaderDefaultInitialValues.state,
    tags: params.tags ?? entitiesTableHeaderDefaultInitialValues.tags,
  };

  const handleFiltersSubmit = async (values) => {
    const query = {
      type: values.type?.value ?? values.type,
      state: values.state?.name ?? values.state,
      search: values.search?.name ?? values.search,
      tags: values.tags?.map((tag) => tag?.value ?? tag),
    };

    try {
      setParams(values);

      await refetch(query);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          innerParams: JSON.stringify(query),
        },
      });

      // try reseting parameters
      setParams({});
    }
  };

  const [t, setT] = useState(null);

  const handleFiltersChange = (values, prev) => {
    if (values.search !== prev.search) {
      if (t) clearTimeout(t);

      setT(setTimeout(() => handleFiltersSubmit(values), 400));
      return;
    }

    handleFiltersSubmit(values);
  };

  const handleReset = () => {
    onReset?.();
    setParams({});
  };

  return (
    <SectionHeader
      enableReinitialize
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersSubmit}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack direction="column">
              <SectionHeaderHeading sx={{ mb: 0.5 }} variant="h3">
                {title}
              </SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LSLen color="primary" />
                  </InputAdornment>
                ),
              }}
              name="search"
              placeholder="Buscar..."
              size="small"
            />

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <SelectField
                options={entityTypes}
                label="Tipo"
                name="type"
                size="small"
              />

              <EntityStatusAutocomplete
                label="Estado"
                name="state"
                size="small"
              />

              <TagsAutocomplete
                multiple
                label="Etiquetas"
                name="tags"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{
                ml: 'auto',
                gap: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.DIRECTORY.CREATE]}
              >
                <Button
                  color="primary"
                  startIcon={<LSAddIcon color="white" />}
                  variant="contained"
                  onClick={() => dispatchEntityFormDialog('EntitiesTableForm')}
                >
                  Crear Directorio
                </Button>
              </PermissionGuard>

              <MoreButton popperHeader="Acciones">
                <Stack gap={0.5} p={1}>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.DIRECTORY.DELETE]}
                  >
                    <Button
                      disabled={!selectedItems.length || isDeleting}
                      size="large"
                      startIcon={<LSTrash />}
                      sx={{ justifyContent: 'flex-start' }}
                      variant="text"
                      onClick={() =>
                        dispatchWarningPromptDialog('EntitiesTableHeader', {
                          onAccept: () =>
                            deleteEntities({
                              variables: {
                                ids: selectedItems,
                                studioId: studioId,
                              },
                            }),
                          title: 'Eliminar directorios',
                        })
                      }
                    >
                      Eliminar
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.DIRECTORY.ACCESS]}
                  >
                    <Button
                      disabled={isDeleting || loadingEntitiesToExport}
                      size="large"
                      startIcon={<Download />}
                      variant="text"
                      onClick={handleDownloadAsExcel}
                    >
                      Descargar Excel ({selectedItems?.length || totalItems})
                    </Button>
                  </PermissionGuard>
                </Stack>
              </MoreButton>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              tags: 'Etiquetas',
              type: 'Tipo',
              state: 'Estado',
              search: 'Busqueda',
            }}
            filters={params}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;
